import { FC, Fragment, useEffect, useState } from "react";
import {
  CheckIcon,
  Squares2X2Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Badge from "shared/Badge/Badge";

import { useLocation, useNavigate } from "react-router-dom";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import { Checkbox, Dialog, DialogPanel, Transition } from "@headlessui/react";
import { useApp } from "data/app-provider";
import { Extras } from "models/ExtrasModel";
import { startAxleIgnition, stripeSession } from "utils/apiUtil";
import AxleIgnitionResponse from "models/AxleIgnitionResponse";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import StripeSessionResponse from "models/StripeSessionResponse";
import toast from "react-hot-toast";

const ListingCarDetail: FC<{}> = () => {
  const [ignitionUrl, setIgnitionUrl] = useState<string | null>();
  const [axleloading, setAxleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const thisPathname = useLocation().pathname;
  const navigate = useNavigate();
  const {
    user,
    selectedCar,
    extras,
    selectedExtras,
    setSelectedExtras,
    startDate,
    endDate,
    pickupLocation,
    dropOffLocation,
    selectedDropOffLocationExtra,
    insuranceProtection,
    bonzahProducts,
    setSelectedBonzahProduct,
    selectedInsuranceProtection,
    setSelectedInsuranceProtection,
    fleetwireToken,
    axleInsurance,
    stripeVerification,
    callFleetwireCustomerAPI,
    fleetwireCustomerLoading,
    subTotal,
    discount,
    discountName,
    discountPercentage,
    extrasTotal,
    totalTax,
    tripFee,
    grossTotal,
    protection,
    setIsAuthPanelOpen,
    tripDays,
  } = useApp();

  let [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!pickupLocation) {
      navigate("/");
    }
  }, [pickupLocation]);

  const handleAxleVerificationButtonClick = async () => {
    if (user == null) {
      toast.error("Please Login/Signup to continue");
      setIsAuthPanelOpen(true);
      return;
    }

    if (axleInsurance == null) {
      try {
        const axleIgnitionResponse = (await startAxleIgnition(
          user?.renterId ?? "",
          fleetwireToken ?? "",
          setAxleLoading
        )) as AxleIgnitionResponse;

        setIgnitionUrl(axleIgnitionResponse?.ignitions?.pop()?.ignition_uri);
        open();
      } catch (error) {
        toast.error("Error starting axle ignition");
      }
    }
  };

  const handlePrimaryDriverVerificationButtonClick = async () => {
    if (user == null) {
      toast.error("Please Login/Signup to continue");
      setIsAuthPanelOpen(true);
      return;
    }
    setLoading(true);
    if (stripeVerification == null) {
      const stripeSessionResponse = (await stripeSession(
        user?.renterId ?? "",
        fleetwireToken ?? ""
      )) as StripeSessionResponse;
      setIgnitionUrl(stripeSessionResponse?.id.url);
      setLoading(false);
      open();
    }
  };

  const handleOpenModalImageGallery = () => {
    navigate(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderSection1 = () => {
    return (
      <div className=" !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge
            color="pink"
            name={selectedCar?.vehicle?.vehicle_class?.name}
          />
          {/* <LikeSaveBtns /> */}
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {selectedCar?.name}
        </h2>
      </div>
    );
  };

  const renderExtrasSection = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Extras</h2>

        {/* CONTENT */}
        {extras.map((extra) => (
          <div
            key={extra.uuid}
            className="w-full rounded-3xl border border-gray-200 flex justify-between items-center p-5"
          >
            <div className="flex justify-start">
              <Checkbox
                checked={selectedExtras.has(extra.uuid)}
                onChange={(checked) => {
                  setSelectedExtras((prevExtras) => {
                    const newExtras = new Map(prevExtras); // Create a copy to avoid mutation
                    if (checked) {
                      newExtras.set(extra.uuid, extra); // Add the extra if selected
                    } else {
                      newExtras.delete(extra.uuid); // Remove the extra if not selected
                    }
                    return newExtras as Map<string, Extras>;
                  });
                }}
                className="group size-14 rounded-md  p-1 ring-1 ring-green-200 ring-inset data-[checked]:bg-green-200 mr-4"
              >
                <CheckIcon
                  className={`size-12 stroke-green-600 ${
                    selectedExtras.has(extra.uuid) ? "visible" : "invisible"
                  }`}
                />
              </Checkbox>
              <div>
                <div className="text-gray-800 dark:text-gray-200 lg:text-xl sm:text-l font-bold flex">
                  {extra.title}
                  {extra.recommended === 1 && (
                    <span className="bg-primary-100 text-white text-center rounded-md text-sm align-middle self-center p-1 ml-2">
                      Recommended
                    </span>
                  )}
                </div>
                <div className="text-gray-400 dark:text-neutral-400 lg:text-lg sm:text-md font-medium ">
                  {extra.options[0].description}
                </div>
              </div>
            </div>

            <div className="text-zinc-600 dark:text-neutral-400 lg:text-4xl sm:2xl font-bold flex items-center xs:text-xl">
              ${extra.options[0].price / 100}/
              <div className="text-gray-400 dark:text-gray-200 lg:text-lg">
                {extra.type}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderAxleSection = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="lg:text-2xl sm:text-xl font-semibold flex justify-between">
          I have my own insurance
          {axleInsurance != null && (
            <CheckBadgeIcon className="fill-green-500 w-12 h-12" />
          )}
          {axleInsurance == null && (
            <ButtonPrimary onClick={handleAxleVerificationButtonClick}>
              {axleloading ? (
                <div className="flex justify-center items-center col-span-full">
                  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-secondary-300"></div>
                </div>
              ) : (
                "Verify"
              )}
            </ButtonPrimary>
          )}
        </h2>
      </div>
    );
  };
  const renderPrimaryDriverVerificationSection = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="lg:text-2xl sm:text-xl font-semibold flex justify-between items-center">
          Primary Driver Verification
          {stripeVerification != null && (
            <CheckBadgeIcon className="fill-green-500 w-12 h-12" />
          )}
          {stripeVerification == null && (
            <ButtonPrimary onClick={handlePrimaryDriverVerificationButtonClick}>
              {loading ? (
                <div className="flex justify-center items-center col-span-full">
                  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-secondary-300"></div>
                </div>
              ) : (
                "Verify"
              )}
            </ButtonPrimary>
          )}{" "}
        </h2>
      </div>
    );
  };

  const renderBonzahSection = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Auto Insurance Verification</h2>

        <div className="w-full rounded-3xl border border-gray-200 flex justify-between items-center p-5">
          <div className="flex justify-start">
            <div>
              <div className="text-gray-800 dark:text-neutral-400 lg:text-xl sm:text-l font-bold">
                I have my own insurance
              </div>
            </div>
          </div>

          {axleInsurance != null && (
            <CheckBadgeIcon className="fill-green-500 w-12 h-12" />
          )}
          {axleInsurance == null && (
            <ButtonPrimary onClick={handleAxleVerificationButtonClick}>
              {axleloading ? (
                <div className="flex justify-center items-center col-span-full">
                  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-secondary-300"></div>
                </div>
              ) : (
                "Verify"
              )}
            </ButtonPrimary>
          )}
        </div>

        {/* CONTENT */}
        {insuranceProtection?.options
          ?.slice(0, 2)
          .map((insuranceOption, index) => (
            <div
              key={index}
              className="w-full rounded-3xl border border-gray-200 flex justify-between items-center p-5"
            >
              <div className="flex justify-start">
                <Checkbox
                  checked={selectedInsuranceProtection
                    ?.map((protection) => protection.title)
                    .includes(insuranceOption.title)}
                  onChange={(checked) => {
                    setSelectedInsuranceProtection([]);
                    setSelectedBonzahProduct([]);
                    if (
                      insuranceOption.title == "Standard Protection" &&
                      checked
                    ) {
                      setSelectedInsuranceProtection([insuranceOption]);
                      bonzahProducts?.products.forEach((product) => {
                        if (
                          product.name.trim() ===
                          "Collision Damage Warranty (CDW)".trim()
                        )
                          setSelectedBonzahProduct((prev) => [
                            ...prev,
                            product,
                          ]);
                        if (
                          product.name.trim() ===
                          "Renter's Contingent Liability Insurance (RCLI) ".trim()
                        )
                          setSelectedBonzahProduct((prev) => [
                            ...prev,
                            product,
                          ]);
                      });
                    } else if (
                      insuranceOption.title == "Minimum Protection" &&
                      checked
                    ) {
                      setSelectedInsuranceProtection([insuranceOption]);
                      bonzahProducts?.products.forEach((product) => {
                        if (
                          product.name.trim() ===
                          "Collision Damage Warranty (CDW)".trim()
                        )
                          setSelectedBonzahProduct((prev) => [
                            ...prev,
                            product,
                          ]);
                      });
                    } else if (
                      insuranceOption.title == "No Protection" &&
                      checked
                    ) {
                      setSelectedInsuranceProtection([insuranceOption]);
                      setSelectedBonzahProduct([]);
                    }
                  }}
                  className={`group size-14 rounded-md  p-1 ring-1
                  ${
                    insuranceOption.title == "No Protection"
                      ? "ring-red-500"
                      : "ring-green-200"
                  } ring-inset ${
                    "data-[checked]:" +
                    (insuranceOption.title == "No Protection"
                      ? "bg-red-200"
                      : "bg-green-200")
                  } mr-4`}
                >
                  <CheckIcon
                    className={`size-12 ${
                      insuranceOption.title == "No Protection"
                        ? "stroke-red-500"
                        : " stroke-green-600"
                    } ${
                      selectedInsuranceProtection
                        .map((protection) => protection.title)
                        .includes(insuranceOption.title)
                        ? "visible"
                        : "invisible"
                    }`}
                  />
                </Checkbox>
                <div>
                  <div
                    className="text-gray-800 dark:text-neutral-400 lg:text-xl sm:text-l font-bold cursor-pointer underline flex items-center"
                    onClick={() => {
                      if (insuranceOption.title == "Standard Protection") {
                        // bonzahProducts?.products.forEach((product) => {
                        // if (
                        //   product.name.trim() ===
                        //   "Renter's Contingent Liability Insurance (RCLI) ".trim()
                        // )
                        window.open(
                          "https://firebasestorage.googleapis.com/v0/b/gtluxerentals-5de60.appspot.com/o/bozah%2FRental%20Insurance.pdf?alt=media&token=38ec18ea-51d6-46e7-9ae6-8ecf86f5591c",
                          "_blank"
                        );
                        // if (
                        //   product.name.trim() ===
                        //   "Collision Damage Warranty (CDW)".trim()
                        // )
                        //   window.open(
                        //     product.productInfo.descriptionOfCoverageUrl,
                        //     "_blank"
                        //   );
                        // });
                      } else if (
                        insuranceOption.title == "Minimum Protection"
                      ) {
                        bonzahProducts?.products.forEach((product) => {
                          if (
                            product.name.trim() ===
                            "Collision Damage Warranty (CDW)".trim()
                          )
                            window.open(
                              product.productInfo.descriptionOfCoverageUrl,
                              "_blank"
                            );
                        });
                      } else
                        bonzahProducts?.products.forEach((product) => {
                          if (product.name.trim() === insuranceOption.title)
                            window.open(
                              product.productInfo.descriptionOfCoverageUrl,
                              "_blank"
                            );
                        });
                    }}
                  >
                    {insuranceOption.title}
                    {insuranceOption.title !== "No Protection" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 20 20"
                        className="ml-2"
                      >
                        <path
                          fill="#1B2430"
                          d="M9.997 18.958c-4.941 0-8.958-4.016-8.958-8.958s4.017-8.958 8.958-8.958c4.942 0 8.959 4.016 8.959 8.958s-4.017 8.958-8.959 8.958Zm0-16.666C5.747 2.292 2.29 5.75 2.29 10s3.458 7.708 7.708 7.708S17.706 14.25 17.706 10s-3.459-7.708-7.709-7.708Z"
                        />
                        <path
                          fill="#1B2430"
                          d="M10 11.458a.63.63 0 0 1-.625-.625V6.667A.63.63 0 0 1 10 6.042a.63.63 0 0 1 .625.625v4.166a.63.63 0 0 1-.625.625Zm-.003 2.709a.829.829 0 0 1-.316-.067.963.963 0 0 1-.275-.175.86.86 0 0 1-.175-.275.83.83 0 0 1-.067-.317.83.83 0 0 1 .067-.316c.041-.1.1-.192.175-.275a.96.96 0 0 1 .275-.175.833.833 0 0 1 .633 0c.1.041.192.1.275.175a.963.963 0 0 1 .175.275.83.83 0 0 1 .067.316.83.83 0 0 1-.067.317.86.86 0 0 1-.175.275.964.964 0 0 1-.275.175.83.83 0 0 1-.317.067Z"
                        />
                      </svg>
                    )}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: insuranceOption.description,
                    }}
                  />
                </div>
              </div>

              <div className="text-zinc-600 dark:text-neutral-400 lg:text-4xl sm:2xl font-bold flex items-center xs:text-xl">
                ${insuranceOption.price / 100}/
                <div className="text-gray-400 dark:text-gray-200 lg:text-lg">
                  {insuranceProtection.type}
                </div>
              </div>
            </div>
          ))}
        <h2 className="text-2xl font-semibold">Insurance Add-Ons</h2>
        {insuranceProtection?.options
          ?.slice(2)
          .map((insuranceOption, index) => (
            <div
              key={index}
              className="w-full rounded-3xl border border-gray-200 flex justify-between items-center p-5"
            >
              <div className="flex justify-start">
                <Checkbox
                  checked={selectedInsuranceProtection
                    ?.map((protection) => protection.title)
                    .includes(insuranceOption.title)}
                  onChange={(checked) => {
                    if (
                      selectedInsuranceProtection.length === 0 &&
                      axleInsurance === null
                    ) {
                      toast.error(
                        "Please verify or select an insurance option before selecting the add-ons."
                      );
                      return;
                    }
                    if (checked) {
                      setSelectedInsuranceProtection((prev) =>
                        prev == null
                          ? [insuranceOption]
                          : [...prev, insuranceOption]
                      );
                      bonzahProducts?.products.forEach((product) => {
                        if (
                          product.name.trim() === insuranceOption.title.trim()
                        )
                          setSelectedBonzahProduct((prev) => [
                            ...prev,
                            product,
                          ]);
                      });
                    } else {
                      if (true) {
                        setSelectedInsuranceProtection((prev) => [
                          ...prev.filter(
                            (item) => item.title !== insuranceOption.title
                          ),
                        ]);
                        setSelectedBonzahProduct((prev) => [
                          ...prev.filter(
                            (product) => product.name !== insuranceOption.title
                          ),
                        ]);
                      }
                    }
                  }}
                  className={
                    "group size-14 rounded-md  p-1 ring-1 ring-green-200 ring-inset data-[checked]:bg-green-200 mr-4"
                  }
                >
                  <CheckIcon
                    className={`size-12 stroke-green-600
                      ${
                        selectedInsuranceProtection
                          .map((protection) => protection.title)
                          .includes(insuranceOption.title)
                          ? "visible"
                          : "invisible"
                      }`}
                  />
                </Checkbox>
                <div className="mr-4">
                  <div
                    className="text-gray-800 dark:text-neutral-400 lg:text-xl sm:text-l font-bold cursor-pointer flex items-center xl:whitespace-nowrap underline"
                    onClick={() => {
                      bonzahProducts?.products.forEach((product) => {
                        if (product.name.trim() === insuranceOption.title)
                          window.open(
                            product.productInfo.descriptionOfCoverageUrl,
                            "_blank"
                          );
                      });
                    }}
                  >
                    {insuranceOption.title}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 20 20"
                      className="ml-2 flex-shrink-0"
                    >
                      <path
                        fill="#1B2430"
                        d="M9.997 18.958c-4.941 0-8.958-4.016-8.958-8.958s4.017-8.958 8.958-8.958c4.942 0 8.959 4.016 8.959 8.958s-4.017 8.958-8.959 8.958Zm0-16.666C5.747 2.292 2.29 5.75 2.29 10s3.458 7.708 7.708 7.708S17.706 14.25 17.706 10s-3.459-7.708-7.709-7.708Z"
                      />
                      <path
                        fill="#1B2430"
                        d="M10 11.458a.63.63 0 0 1-.625-.625V6.667A.63.63 0 0 1 10 6.042a.63.63 0 0 1 .625.625v4.166a.63.63 0 0 1-.625.625Zm-.003 2.709a.829.829 0 0 1-.316-.067.963.963 0 0 1-.275-.175.86.86 0 0 1-.175-.275.83.83 0 0 1-.067-.317.83.83 0 0 1 .067-.316c.041-.1.1-.192.175-.275a.96.96 0 0 1 .275-.175.833.833 0 0 1 .633 0c.1.041.192.1.275.175a.963.963 0 0 1 .175.275.83.83 0 0 1 .067.316.83.83 0 0 1-.067.317.86.86 0 0 1-.175.275.964.964 0 0 1-.275.175.83.83 0 0 1-.317.067Z"
                      />
                    </svg>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: insuranceOption.description,
                    }}
                  />
                </div>
              </div>

              <div className="text-zinc-600 dark:text-neutral-400 lg:text-4xl sm:2xl font-bold flex items-center xs:text-xl">
                ${insuranceOption.price / 100}/
                <div className="text-gray-400 dark:text-gray-200 lg:text-lg">
                  {insuranceProtection.type}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  const renderSidebarPrice = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}

        <div className="w-full max-w-sm mx-auto bg-white dark:bg-gray-800">
          <h2 className="text-xl font-medium text-gray-900 dark:text-gray-100">
            Rental Bill
          </h2>
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            Prices may change depending on the length of the rental and the
            price of your rental car.
          </p>
          <div className="mt-4">
            <div className="flex justify-between items-center">
              <div>
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Subtotal
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {" "}
                  ($
                  {((selectedCar?.pricing?.amount ?? 0) / 100).toFixed(
                    2
                  )} *{" "}
                  {(
                    subTotal /
                    ((selectedCar?.pricing?.amount ?? 1) / 100)
                  ).toFixed(2)}{" "}
                  days)
                </span>
              </div>
              <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                ${subTotal.toFixed(2)}
              </span>
            </div>
          </div>
          {selectedInsuranceProtection?.map((selectedBonzahOption, index) => (
            <div className="mt-4" key={index}>
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    {selectedBonzahOption?.title ?? "Insurance Coverage"}{" "}
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {" "}
                    ($
                    {((selectedBonzahOption?.price ?? 0) / 100).toFixed(
                      2
                    )} * {tripDays().toFixed(2)}
                    days)
                  </span>
                </div>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  $
                  {(
                    ((selectedBonzahOption?.price ?? 0) / 100) *
                    tripDays()
                  ).toFixed(2)}
                </span>
              </div>
            </div>
          ))}
          <div className="mt-4">
            <div className="flex justify-between items-center">
              <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                Delivery Charges (Pickup)
              </span>
              <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                ${((pickupLocation?.price ?? 0) / 100).toFixed(2)}
              </span>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex justify-between items-center">
              <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                Delivery Charges (Drop-off)
              </span>
              <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                ${((selectedDropOffLocationExtra?.price ?? 0) / 100).toFixed(2)}
              </span>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex justify-between items-center">
              <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                Extras
              </span>
              <span className="lg:text-2xl sm:text-xl font-medium text-gray-900 dark:text-gray-100">
                ${extrasTotal.toFixed(2)}
              </span>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex justify-between items-center">
              <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                Tax
              </span>
              <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                ${totalTax.toFixed(2)}
              </span>
            </div>
          </div>

          <div className="mt-4">
            <div className="flex justify-between items-center">
              <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                Booking Fee
              </span>
              <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                ${tripFee.toFixed(2)}
              </span>
            </div>
          </div>

          {discount !== 0 && (
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Discount
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  -${discount.toFixed(2)}
                </span>
              </div>
            </div>
          )}
          {discount !== 0 && (
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  {discountName}
                </span>
                <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                  {discountPercentage}%
                </span>
              </div>
            </div>
          )}
          <div className="mt-8 border-t border-gray-200 dark:border-gray-700 pt-4">
            <div className="flex justify-between items-center">
              <div>
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Total Rental Price
                </span>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  Overall price rental
                </p>
              </div>
              <div>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${grossTotal().toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary
          className="hidden lg:block rounded-md"
          onClick={() => {
            if (user == null) {
              sessionStorage.setItem("redirectUrl", "listing-car-detail");
              toast.error("Please Login/Signup to continue");
              setIsAuthPanelOpen(true);
              return;
            }
            if (stripeVerification == null) {
              toast.error(
                "Please complete stripe verification to move forward"
              );
              return;
            }
            if (
              (axleInsurance?.results["isActive"] === false ||
                axleInsurance == null) &&
              selectedInsuranceProtection.length === 0
            ) {
              toast.error(
                "Either verify your insurance with axle or select an inurance coverage option"
              );
              return;
            }
            navigate("/checkout");
          }}
        >
          Reserve
        </ButtonPrimary>
      </div>
    );
  };

  const renderSidebarDetail = () => {
    function formatDate(date: Date) {
      const dayNameFormat = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
      });
      const monthDayFormat = new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "numeric",
      });
      const timeFormat = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      const dayName = dayNameFormat.format(date);
      const monthDay = monthDayFormat.format(date);
      const time = timeFormat.format(date);

      return `${dayName}, ${monthDay} · ${time}`;
    }
    return (
      <div className="listingSection__wrap lg:shadow-xl">
        <span className="text-2xl font-semibold block">
          Pick up and drop off
        </span>
        <div className="mt-8 flex">
          <div className="flex-shrink-0 flex flex-col items-center py-2">
            <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
            <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
          </div>
          <div className="ml-4 space-y-14 text-sm">
            <div className="flex flex-col space-y-2">
              <span className=" text-neutral-500 dark:text-neutral-400">
                {formatDate(startDate)}
              </span>
              <span className=" font-semibold">{pickupLocation?.name}</span>
            </div>
            <div className="flex flex-col space-y-2">
              <span className=" text-neutral-500 dark:text-neutral-400">
                {formatDate(endDate)}
              </span>
              <span className=" font-semibold">
                {dropOffLocation?.name ?? pickupLocation?.name}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={` nc-ListingCarDetailPage `}>
        {/* SINGLE HEADER */}
        <header className="rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-4 gap-1 sm:gap-2 lg:min-h-96 md:min-h-0">
            <div
              className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={handleOpenModalImageGallery}
            >
              <img
                src={
                  (selectedCar?.listing_images?.length ?? 0) > 0
                    ? selectedCar?.listing_images[0].path
                    : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
                }
                alt="0"
                className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>

            {/*  */}
            <div
              className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={handleOpenModalImageGallery}
            >
              <img
                className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                src={
                  (selectedCar?.listing_images?.length ?? 0) > 1
                    ? selectedCar?.listing_images[1].path
                    : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
                }
                alt="1"
                sizes="400px"
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>

            {/*  */}
            {selectedCar?.listing_images
              .filter((_, i) => i >= 0 && i < 4)
              .map((item, index) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    index >= 2 ? "block" : ""
                  }`}
                >
                  <div className="aspect-w-4 aspect-h-3">
                    <img
                      className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl "
                      src={item.path || ""}
                      alt="photos"
                      sizes="400px"
                    />
                  </div>

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={handleOpenModalImageGallery}
                  />
                </div>
              ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={handleOpenModalImageGallery}
            >
              <Squares2X2Icon className="h-5 w-5" />

              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>

        {/* MAIn */}
        <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
          {/* CONTENT */}
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
            {renderSection1()}
            <div className="block lg:hidden">{renderSidebarDetail()}</div>
            {renderPrimaryDriverVerificationSection()}
            {/* {renderAxleSection()} */}
            {renderBonzahSection()}
            {renderExtrasSection()}
          </div>

          {/* SIDEBAR */}
          <div className="block flex-grow mt-0 lg:mt-0">
            {/* {renderSidebarDetail()} */}
            <div className="hidden lg:block mt-10 sticky top-28">
              {renderSidebarPrice()}
            </div>
          </div>
        </main>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={open}
          static={true}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <DialogPanel className="relative h-full w-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={close}
                      >
                        <XMarkIcon
                          onClick={async () => {
                            await callFleetwireCustomerAPI();
                            close();
                          }}
                          className="w-5 h-5 text-black dark:text-white"
                        />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <div className="p-5">
                            <span className=" font-semibold text-xl sm:text-2xl flex justify-center">
                              Verification
                            </span>
                            <span className=" font-semibold text-lg sm:text-xl flex justify-center ">
                              This takes time. Please wait.
                            </span>
                          </div>
                          <div className="flex-1 relative flex z-10 ">
                            <div className="grid justify-center w-full overflow-hidden rounded-3xl ">
                              <iframe
                                title="Verification"
                                width="100%"
                                height="100%"
                                className="w-full h-full"
                                src={ignitionUrl ?? ""}
                                allow={"camera " + ignitionUrl}
                                allowFullScreen
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                      <div></div>
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        loading={fleetwireCustomerLoading}
                        onClick={async () => {
                          await callFleetwireCustomerAPI();
                          close();
                        }}
                      >
                        Done
                      </ButtonPrimary>
                    </div>
                  </>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default function ListingCarDetailPage() {
  return (
    <DetailPagetLayout>
      <ListingCarDetail />
    </DetailPagetLayout>
  );
}
