import { Fragment, useEffect, useState } from "react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useTimeoutFn } from "react-use";
import CarsSearchForm from "./(car-search-form)/CarsSearchForm";
import { useApp } from "data/app-provider";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const HeroSearchForm2Mobile = () => {
  const {
    companyData,
    startDate,
    endDate,
    callAvailableFleetAPI,
    callBonzahAPI,
    availableFleetLoading,
    bonzahLoading,
    pickupLocation,
    startHours,
    startMinutes,
    startPeriod,
    setStartHours,
    setStartMinutes,
    setStartPeriod,
    endHours,
    endMinutes,
    endPeriod,
    setEndHours,
    setEndMinutes,
    setEndPeriod,
  } = useApp();

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  const [showDialog, setShowDialog] = useState(false);

  const hoursOptions = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
  const minutesOptions = Array.from({ length: 60 }, (_, i) =>
    String(i).padStart(2, "0")
  );
  const periodOptions = ["AM", "PM"];

  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);
  //

  useEffect(() => {
    validateStartDateTime();
  }, [startDate, endDate]);
  useEffect(() => {
    validateEndTime();
  }, [startDate]);

  const validateStartDateTime = () => {
    if (startDate < new Date()) {
      toast.error(
        "Trip start date and time cannot be in the past. Please choose a date and time after the current time."
      );
    }
    return startDate < new Date();
  };
  const validateEndTime = () => {
    if (
      startDate &&
      endDate &&
      startDate.toDateString() === endDate.toDateString()
    ) {
      const startTime = new Date();
      startTime.setHours(
        parseInt(startHours) + (startPeriod === "PM" ? 12 : 0),
        parseInt(startMinutes)
      );

      const endTime = new Date();
      endTime.setHours(
        parseInt(endHours) + (endPeriod === "PM" ? 12 : 0),
        parseInt(endMinutes)
      );

      if (endTime < startTime) {
        toast.error("Trip end date time cannot be before trip start date time");
        setEndHours(startHours);
        setEndMinutes(startMinutes);
        setEndPeriod(startPeriod);
      }
    }
  };

  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return (
      <button
        onClick={openModal}
        className="relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 rounded-full shadow-lg"
      >
     

        <div className=" flex-1 text-left overflow-hidden">
          <span className="text-sm font-normal">
            Select Location, Date & Time.{" "}
            <span className="text-primary-200">Start your journey here</span>
          </span>
          <div className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 "></div>
        </div>

        <MagnifyingGlassIcon className="flex-shrink-0 w-5 h-5" />
      </button>
    );
  };

  function isBeforeOrAfterLagTime() {
    // Convert lagTimeValue to integer
    const lagTimeValueInt: number =
      companyData?.company?.meta?.booking_lag_time_value ?? 0;
    if (isNaN(lagTimeValueInt)) {
      throw new Error("lagTimeValue must be a number");
    }

    // Get the appropriate time delta based on lagTimeInterval
    const timeDeltaMap: any = {
      hours: new Date().setTime(
        new Date().getTime() + lagTimeValueInt * 60 * 60 * 1000
      ),
      days: new Date().setTime(
        new Date().getTime() + lagTimeValueInt * 24 * 60 * 60 * 1000
      ),
      weeks: new Date().setTime(
        new Date().getTime() + lagTimeValueInt * 7 * 24 * 60 * 60 * 1000
      ),
    };

    const lagTime =
      timeDeltaMap[
        (
          companyData?.company?.meta?.booking_lag_time_interval ?? ""
        ).toLowerCase()
      ];
    if (!lagTime) {
      return true;
    }

    // Compare start date with lag time
    if (startDate.getTime() < lagTime) {
      return false;
    } else if (startDate.getTime() > lagTime) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="HeroSearchForm2Mobile">
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-max"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-scroll flex-1 flex flex-col justify-between ">
                  {showDialog && (
                    <Tab.Group manual>
                      <div className="absolute left-4 top-4">
                        <button className="" onClick={closeModal}>
                          <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                        </button>
                      </div>
                      <div className="flex-1 pt-7 px-1.5 sm:px-4 flex overflow-hidden">
                        <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <CarsSearchForm
                                setShowModal={setShowModal}
                                setStartHours={setStartHours}
                                setEndHours={setEndHours}
                                endHours={endHours}
                                startHours={startHours}
                                setEndMinutes={setEndMinutes}
                                setStartMinutes={setStartMinutes}
                                endMinutes={endMinutes}
                                startMinutes={startMinutes}
                                setEndPeriod={setEndPeriod}
                                setStartPeriod={setStartPeriod}
                                endPeriod={endPeriod}
                                startPeriod={startPeriod}
                              />
                            </div>
                          </Tab.Panel>
                        </Tab.Panels>
                      </div>
                      <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                        {/* <button
                          type="button"
                          className="underline font-semibold flex-shrink-0"
                          onClick={() => {
                            setShowDialog(false);
                            resetIsShowingDialog();
                          }}
                        >
                          Clear all
                        </button> */}
                        <button
                          onClick={() => {
                            if (isBeforeOrAfterLagTime() == false) {
                              toast.error(
                                `Unavailable within next ${companyData?.company?.meta?.booking_lag_time_value} ${companyData?.company?.meta?.booking_lag_time_interval} . Please select another time.`
                              );
                            } else if (pickupLocation == null) {
                              toast.error("Please enter Pick up location");
                            } else if (
                              pickupLocation != null &&
                              validateStartDateTime() == false
                            ) {
                              callAvailableFleetAPI(navigate);
                              callBonzahAPI();
                            }
                            Promise.all([
                              callAvailableFleetAPI(navigate),
                              callBonzahAPI(),
                            ]).then(() => {
                              closeModal();
                            });
                          }}
                          className={`flex-shrink-0 px-4 py-2.5 cursor-pointer rounded-xl bg-primary-6000 flex items-center justify-center text-neutral-50 focus:outline-none relative z-20 w-full`}
                        >
                          {availableFleetLoading || bonzahLoading ? (
                            <svg
                              aria-hidden="true"
                              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-400"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          ) : (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1.5}
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                />
                              </svg>
                              <span className="ml-2">Search</span>
                            </>
                          )}
                        </button>
                      </div>
                    </Tab.Group>
                  )}
                </Dialog.Panel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HeroSearchForm2Mobile;
