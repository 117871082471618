import rightImg from "images/about-hero-right.png";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "containers/PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import SectionHero4 from "components/SectionHero/SectionHero";

function PageHome3() {
  return (
    <div className="nc-PageHome3 relative overflow-hidden mt-4">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      {/* <DownloadLink /> */}

      {/* SECTION HERO */}
      <div className="container relative lg:pb-16 lg:pt-8 md:pt-0 sm:pt-0 px-0 md:pb-0 sm:pb-0 ">
        <div className="container">
          <SectionHero4 className="" />
        </div>
      </div>
      <div className="container relative py-16">
        <SectionGridFeaturePlaces />
      </div>

      <div className="container relative" style={{ padding: "0px" }}>
        {/* ======== BG GLASS ======== */}
        <BgGlassmorphism />

        <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
          <SectionHero
            rightImg={rightImg}
            heading="About Us."
            btnText=""
            subHeading={globalJson?.about_us_para}
          />

          <div className="relative py-16 mb-20 lg:mb-36">
            {/* <BackgroundSection /> */}
            <SectionClientSay uniqueClassName="PageAbout_" />
          </div>

          <SectionSubscribe2 />
        </div>
      </div>
    </div>
  );
}

export default PageHome3;
