import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { useApp } from "data/app-provider";
import { Insurance, StripeVerification } from "models/FleetwireCustomer";
import { BonzahProduct } from "models/BonzahProducts";
// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    flexDirection: "column",
    display: "flex",
  },
  heading1: {
    //    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 22,
    color: "#000000",
  },
  heading2: {
    //    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 22,
    color: "#FF0000",
  },
  heading3: {
    //    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 14,
    color: "#000000",
  },
  text: {
    //    fontFamily: "Arial",
    fontSize: 14,
    color: "#000000",
  },
  textBold: {
    //    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 14,
    color: "#000000",
  },
  section: {
    marginBottom: 10,
  },
  headerSection: {
    marginTop: 30,
    marginBottom: 10,
  },
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    marginBottom: 10,
    flexDirection: "column",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
  },
  tableCol: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },
  tableCell: {
    margin: 5,
    fontSize: 14,
    // fontFamily: "Arial",
  },
  tableHeader: {
    fontWeight: "bold",
    backgroundColor: "#d3d3d3",
  },
});

const signatureStylesText = [
  "Playball",
  "Qwitcher Grypen",
  "Montez",
  "My Soul",
  "Ms Madi",
  "Niconne",
];

// // Register fonts with direct URLs
Font.register({
  family: "Playball",
  src: "/fonts/Playball-Regular.ttf",
});
Font.register({
  family: "Qwitcher Grypen",
  src: "/fonts/QwitcherGrypen-Regular.ttf",
});
Font.register({
  family: "Montez",
  src: "/fonts/Montez-Regular.ttf",
});
Font.register({
  family: "My Soul",
  src: "/fonts/MySoul-Regular.ttf",
});
Font.register({
  family: "Ms Madi",
  src: "/fonts/MsMadi-Regular.ttf",
});
Font.register({
  family: "Niconne",
  src: "/fonts/Niconne-Regular.ttf",
});

// Create Document Component

export interface RentalAgreemaentProps {
  selectedSignatureIndex: number;
  stripeVerification: StripeVerification;
  selectedBonzahProduct: BonzahProduct[];
  axleInsurance: Insurance | null;
  bonzahCertificate?: string;
}
const RentalAgreement: React.FC<RentalAgreemaentProps> = ({
  selectedSignatureIndex,
  stripeVerification,
  selectedBonzahProduct,
  axleInsurance,
  bonzahCertificate,
}) => {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.heading1}>Privacy Policy</Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            We value your privacy and are committed to protecting your personal
            information. This Privacy Policy outlines how we collect, use, and
            safeguard your data when you use our services.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}>Information Collection</Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            We collect personal information from you when you:
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            • Make a reservation or rent a vehicle
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}> • Contact us for customer service</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            • Interact with our website or social media pages
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.textBold}>Information Use</Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            We use your personal information to:
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}> • Process your rental transaction</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            • Communicate with you regarding your rental and our services
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            • Improve our services and customer experience
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.textBold}>Information Sharing</Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            We do not sell or share your personal information with third
            parties, except as necessary to:
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}> • Complete your rental transaction</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}> • Comply with legal obligations</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}> • Protect our rights and property</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.textBold}>Data Security </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            We implement industry-standard security measures to protect your
            personal information from unauthorized access, use, or disclosure.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.textBold}>Your Rights </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            You have the right to access, update, or delete your personal
            information. To exercise these rights, please contact us at the
            provided contact details.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.textBold}>Changes to this Policy </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            We may update this Privacy Policy periodically. Any changes will be
            posted on our website with the updated policy effective date.
          </Text>
        </View>

        <View style={styles.headerSection}>
          <Text style={styles.heading1}>Cancellation Policy</Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            We understand that plans can change. Our cancellation policy is
            designed to provide flexibility while ensuring fairness for all
            parties.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.textBold, ...styles.section }}>
            Cancellation Terms
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • 48 Hours Before Rental: </Text>
          <Text style={styles.text}>Full refund of any prepaid amount.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • 24-48 Hours Before Rental: </Text>
          <Text style={styles.text}>50% refund of any prepaid amount.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}>
            • Less than 24 Hours Before Rental:
          </Text>
          <Text style={styles.text}>No refund.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}>No-Show Policy: </Text>
          <Text style={styles.text}>
            If you fail to pick up the vehicle within 24 hours of the scheduled
            rental time without prior notice, no refund will be issued, and the
            reservation will be cancelled.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.textBold}>Modifications: </Text>
          <Text style={styles.text}>
            If you need to modify your reservation, please contact us as soon as
            possible. We will do our best to accommodate changes, subject to
            vehicle availability.
          </Text>
        </View>

        <View style={styles.headerSection}>
          <Text style={styles.heading1}>Terms and Conditions</Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            These Terms and Conditions govern the rental of vehicles from our
            company. By signing the rental agreement, you agree to comply with
            these terms.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.textBold, ...styles.section }}>
            Definitions
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.textBold}> • Agreement: </Text>
          <Text style={styles.text}>
            All terms and conditions in this document.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.textBold}> • You/Your: </Text>
          <Text style={styles.text}>
            The customer, any authorized driver, or any person or organization
            to whom charges are billed.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • We/Our/Us: </Text>
          <Text style={styles.text}>
            The rental company named in the agreement.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • Authorized Driver: </Text>
          <Text style={styles.text}>
            The renter and any additional driver listed in the agreement with a
            valid driver's license and at least 21 years old.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • Vehicle: </Text>
          <Text style={styles.text}>
            The automobile or truck identified in the agreement, including all
            parts and accessories.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • Physical Damage: </Text>
          <Text style={styles.text}>
            Damage to or loss of the vehicle caused by collision or upset,
            excluding comprehensive damage such as theft, vandalism, or natural
            disasters.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • Loss of Use: </Text>
          <Text style={styles.text}>
            The loss of our ability to use the vehicle during repair or
            replacement, calculated by the daily rental rate.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}>Rental, Indemnity, and Warranties</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            This agreement is a contract for the rental of the vehicle.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            We may repossess the vehicle at your expense without notice if it is
            abandoned or used in violation of the agreement.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            You agree to indemnify and hold us harmless from all claims,
            liabilities, costs, and attorney fees arising from this rental and
            your use of the vehicle.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            We make no warranties regarding the vehicle, including warranties of
            merchantability or fitness for a particular purpose.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.textBold}> Condition and Return of Vehicle </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            You must return the vehicle on the date and time specified, in the
            same condition as received, except for ordinary wear.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            If returned after hours, you are responsible for any damage until we
            inspect it.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            Service or replacement of parts during the rental requires our prior
            approval. You must maintain all fluid levels.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.textBold}>Responsibility for Damage or Loss</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            You are responsible for all loss or theft of, or damage to, the
            vehicle, including repair costs, loss of use, diminished value, and
            administrative expenses.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            You must report all accidents, thefts, and vandalism to us and the
            police immediately.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}>Prohibited Uses </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            Unauthorized drivers, driving under the influence, using the vehicle
            for illegal purposes, or in violation of traffic laws are
            prohibited.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            Transporting hazardous materials, racing, off-road driving, and
            other specified activities are prohibited.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            Breaching these terms results in your liability for all damages and
            our right to repossess the vehicle.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}>Insurance </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            You are responsible for providing auto liability, collision, and
            comprehensive insurance.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            Where required, we provide secondary auto liability insurance with
            minimum coverage as prescribed by law.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            The policy is void if you violate the terms of this agreement or
            fail to cooperate in any loss investigation.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}>Charges</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            You agree to pay all charges related to the rental, including time
            and mileage, additional drivers, optional products, fuel, taxes,
            fines, recovery fees, and cleaning fees.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            Late payment fees, returned check fees, and costs for collecting
            payment or enforcing our rights may apply.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.textBold}>Deposit</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            We may use your deposit to cover any amounts owed under this
            agreement.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.textBold}>Your Property</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            We are not responsible for loss or damage to your personal property
            or that of others carried in or on the vehicle.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}>Modifications </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            No term of this agreement can be waived or modified except in
            writing by us.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            This agreement constitutes the entire agreement between you and us,
            voiding all prior representations and agreements.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}>Miscellaneous </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            Our acceptance of payment or failure to exercise any rights does not
            constitute a waiver of other provisions.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            You release us from liability for consequential, special, or
            punitive damages.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}> • </Text>
          <Text style={styles.text}>
            If any provision is deemed unenforceable, the remaining provisions
            remain valid.
          </Text>
        </View>

        <View style={styles.headerSection}>
          <Text style={{ ...styles.heading1, ...styles.section }}>
            Primary Driver
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>
            Name: {stripeVerification?.person_details.first_name}{" "}
            {stripeVerification?.person_details.last_name}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>
            DL#:{" "}
            {stripeVerification?.person_details.document_number === ""
              ? "N/A"
              : stripeVerification?.person_details.document_number}{" "}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>
            Issued Date: {stripeVerification?.person_details.issued_date?.month}
            /{stripeVerification?.person_details.issued_date?.day}/
            {stripeVerification?.person_details.issued_date?.year}{" "}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading1}>Insurance</Text>
        </View>

        {selectedBonzahProduct.length != 0 ? (
          selectedBonzahProduct?.map((selectedBonzahOption) => (
            <View style={styles.section}>
              <Text style={styles.heading3}>
                Insurance Name : {selectedBonzahOption.name}
              </Text>
            </View>
          ))
        ) : (
          <View style={styles.section}>
            <Text style={styles.heading3}>
              Insurance Policy # : {axleInsurance?.results["policyNumber"]}
            </Text>
          </View>
        )}
        {selectedBonzahProduct.length != 0 ? (
          <View style={styles.section}>
            <Text style={styles.heading3}>Insurance Provided by : Bonzah</Text>
          </View>
        ) : (
          <View style={styles.section}>
            <Text style={styles.heading3}>
              Insurance Company : {axleInsurance?.results["carrier"]}
            </Text>
          </View>
        )}
        {selectedBonzahProduct.length != 0 && (
          <View style={styles.section}>
            <Text style={styles.heading3}>
              Insurance agreement :{" "}
              {bonzahCertificate !== null
                ? bonzahCertificate
                : "Will be provided in the email"}
            </Text>
          </View>
        )}
        <View style={styles.section}>
          <Text style={styles.heading1}>Signature</Text>
        </View>
        <View style={styles.section}>
          <Text
            style={{
              ...styles.heading1,
              ...{ fontFamily: signatureStylesText[selectedSignatureIndex] },
            }}
          >
            {stripeVerification?.person_details.first_name}{" "}
            {stripeVerification?.person_details.last_name}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const DownloadLink: React.FC = () => {
  const {
    selectedBonzahProduct,
    stripeVerification,
    selectedSignatureIndex,
    axleInsurance,
  } = useApp();
  return (
    <div>
      <PDFDownloadLink
        document={
          <RentalAgreement
            selectedSignatureIndex={selectedSignatureIndex}
            stripeVerification={stripeVerification!}
            selectedBonzahProduct={selectedBonzahProduct}
            axleInsurance={axleInsurance}
          />
        }
        fileName="rental_agreement.pdf"
        className=" cursor-pointer"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Rental Agreement"
        }
      </PDFDownloadLink>
    </div>
  );
};

export default RentalAgreement;
